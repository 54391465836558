import { Route, Routes } from "react-router-dom";
import "./App.css";

import "aos/dist/aos.css";

import "./assets/css/style.css";
import AOS from "aos";

import { Layout } from "./Layout/Layout";
import { Home } from "./Pages/Home";
import { Services } from "./Pages/Services";
import { Contact } from "./Pages/Contact";
// import {PrivacyPolicy} from './Pages/PrivacyPolicy';
// import {TermsConditions} from './Pages/TermsConditions';

AOS.init();
function App() {
  return (
    <div className="App" style={{ textAlign: "left" }}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
          {/* <Route path="/terms-conditions" element={<TermsConditions />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
