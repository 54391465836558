import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodepen, faAws } from "@fortawesome/free-brands-svg-icons";
import {
  faLaptopCode,
  faMobileScreen,
  faGlobe,
  faFileCode,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Services = () => {
  const pathname = useLocation();
  const scrollToElement = async (id) => {
    document.getElementById(id).scrollIntoView();
  };

  useEffect(() => {
    if (pathname.hash.includes("#")) {
      console.log(pathname.hash);
      const id = pathname.hash.split("#")[1];
      scrollToElement(id);
    }
  }, [pathname.hash]);
  return (
    <div>
      <Helmet>
        <title>ProLeap Systems || Services</title>
      </Helmet>
      <section
        className="hero-wrap hero-wrap-2"
        style={{
          backgroundImage: "url(images/xbg_3.jpg.pagespeed.ic.M6GN2K7j8o.jpg)",
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-center">
            <div className="col-md-9 text-center mb-5">
              <p className="breadcrumbs">
                <span className="me-2">
                  <Link to="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>
                <span>
                  Services <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 className="mb-0 bread">Services</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-section bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-5 heading-section text-center mb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <span className="subheading">Services</span>
              <h2 className="mb-4">Our Exclusive Services We Offer For You</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-md-3 text-center d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <Link to="/services#itconsulting" style={{ width: "100%" }}>
                <div className="flow-wrap flow-wrap-right">
                  <div className="icon">
                    <FontAwesomeIcon icon={faFileCode} size="4x" />
                  </div>
                  <div className="text">
                    <h2>IT Consulting</h2>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-3 text-center d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <Link to="/services#MAD" style={{ width: "100%" }}>
                <div className="flow-wrap">
                  <div className="icon">
                    <FontAwesomeIcon icon={faMobileScreen} size="4x" />
                  </div>
                  <div className="text">
                    <h2>Mobile Application Development</h2>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-3 text-center d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <Link to="/services#SD" style={{ width: "100%" }}>
                <div className="flow-wrap">
                  <div className="icon">
                    <FontAwesomeIcon icon={faCodepen} size="4x" />
                  </div>
                  <div className="text">
                    <h2>Software Development</h2>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-3 text-center d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <Link to="/services#webui" style={{ width: "100%" }}>
                <div className="flow-wrap">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLaptopCode} size="4x" />
                  </div>
                  <div className="text">
                    <h2>Web/UI Development</h2>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-3 text-center d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <Link to="/services#QA" style={{ width: "100%" }}>
                <div className="flow-wrap">
                  <div className="icon">
                    <FontAwesomeIcon icon={faHandshake} size="4x" />
                  </div>
                  <div className="text">
                    <h2>Quality Assurance</h2>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-3 text-center d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <Link to="/services#network" style={{ width: "100%" }}>
                <div className="flow-wrap">
                  <div className="icon">
                    <FontAwesomeIcon icon={faGlobe} size="4x" />
                  </div>
                  <div className="text">
                    <h2>Network Management</h2>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-md-3 text-center d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <Link to="/services#devops" style={{ width: "100%" }}>
                <div className="flow-wrap">
                  <div className="icon">
                    <FontAwesomeIcon icon={faAws} size="4x" />
                  </div>
                  <div className="text">
                    <h2>DevOps</h2>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div
              className="col-md-3 text-center d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <div className="flow-wrap">
                <div className="icon">
                  <span className="flaticon-technology"></span>
                </div>
                <div className="text">
                  <h2>Technology Consulting</h2>
                  <p className="mb-4">
                    Far far away, behind the word mountains, far from the
                    countries Vokalia.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div id="itconsulting">
            <div className="d-flex px-8 py-5 flex-column flex-md-row row-gap-4">
              <div
                className="col-md-6 section-icon-container d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-delay="100"
                data-duration-duration="1000"
              >
                <FontAwesomeIcon icon={faFileCode} size="8x" color="#4f86f9" />
              </div>
              <div className="col-md-6">
                <h2>IT Consulting</h2>
                <div>
                  Use the expertise and deep tech background of the best minds
                  at Proleap Systems to create a comprehensive IT strategy for a
                  digital and technological transformation of your organization
                  that goes in line with your business objectives. Our strategic
                  IT consulting will help you automate and digitalize
                  operations, optimize the software portfolio, and implement the
                  latest technologies. Our software engineers will finish your
                  digital transformation journey through careful planning and
                  effective execution of the outlined IT strategy.
                </div>
              </div>
            </div>
          </div>
          <div id="MAD" className="bg-light">
            <div className="d-flex container py-5 flex-column flex-md-row-reverse row-gap-4">
              <div
                className="col-md-6 section-icon-container d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-delay="100"
                data-duration-duration="1000"
              >
                <FontAwesomeIcon
                  icon={faMobileScreen}
                  size="8x"
                  color="#4f86f9"
                />
              </div>
              <div className="col-md-6">
                <h2>Mobile Application Development</h2>
                <div>
                  Mobile applications are an integral part of our lives, and
                  this is the main reason why we all demand them being of the
                  highest quality. Our developers are keen on supreme delivery,
                  and when it comes to App development — there can be no other
                  way. Our extensive knowledge of the best development practices
                  and methodologies drives us forth the service that is
                  beneficial for both a client and our experts.
                </div>
                <div>
                  We value transparency in relationships with all our clients,
                  as they value our dedication to their products. We work with
                  customers from all over the world and deliver ready solutions
                  to numerous markets. We are fond of android &#38; ios app
                  development, no matter native or hybrid —We believe that edgy
                  technology can make the world better. We can help you connect
                  your customers and employees to corporate systems, create a
                  mobile software product or a client application to your own or
                  third-party information service.
                </div>
              </div>
            </div>
          </div>
          <div id="SD">
            <div className="d-flex container py-5 flex-column flex-md-row row-gap-4">
              <div
                className="col-md-6 section-icon-container d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-delay="100"
                data-duration-duration="1000"
              >
                <FontAwesomeIcon icon={faCodepen} size="8x" color="#4f86f9" />
              </div>
              <div className="col-md-6">
                <h2>Software Development</h2>
                <div>
                  The development of reliable and scalable software solutions
                  for any OS, browser, and device. We bring together deep
                  industry expertise and the latest IT advancements to deliver
                  custom solutions and products that perfectly fit the needs and
                  behavior of their users.
                </div>
                <ul>
                  <li>Software consulting</li>
                  <li>Custom software development</li>
                  <li>Software product development</li>
                  <li>Cloud application development</li>
                  <li>Legacy software Modernization</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="webui" className="bg-light">
            <div className="d-flex container py-5 flex-column flex-md-row-reverse row-gap-4">
              <div
                className="col-md-6 section-icon-container d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-delay="100"
                data-duration-duration="1000"
              >
                <FontAwesomeIcon
                  icon={faLaptopCode}
                  size="8x"
                  color="#4f86f9"
                />
              </div>
              <div className="col-md-6">
                <h2>Web/UI Development</h2>
                <div>
                  Want to build your product with a team that establishes a
                  clear design process, meets deadlines, and delivers a spot-on
                  end result? Turn to Proleap systems UI and UX services. Our
                  Visual design engineers will help you build an engaging
                  product easily and quickly.
                </div>
              </div>
            </div>
          </div>
          <div id="QA">
            <div className="d-flex container py-5 flex-column flex-md-row row-gap-4">
              <div
                className="col-md-6 section-icon-container d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-delay="100"
                data-duration-duration="1000"
              >
                <FontAwesomeIcon icon={faHandshake} size="8x" color="#4f86f9" />
              </div>
              <div className="col-md-6">
                <h2>Quality Assurance</h2>
                <div>
                  Our QA services will help you establish concrete control over
                  your product’s life cycle, monitor every development stage,
                  and give you accurate product quality information. our QA
                  experts have been using the latest practices and technologies
                  to ensure efficient performance and high-quality project
                  implementation for the world’s largest enterprises, aiding in
                  delivering solid software on time.
                </div>
              </div>
            </div>
          </div>
          <div id="network" className="bg-light">
            <div className="d-flex container py-5 flex-column flex-md-row-reverse row-gap-4">
              <div
                className="col-md-6 section-icon-container d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-delay="100"
                data-duration-duration="1000"
              >
                <FontAwesomeIcon icon={faGlobe} size="8x" color="#4f86f9" />
              </div>
              <div className="col-md-6">
                <h2>Network Management</h2>
                <div>
                  Whether or not you use our Managed Network Services, our
                  Network Consulting Services can help you optimize network
                  performance, mitigate the risk of downtime, and develop a
                  network strategy that supports and enables your organization’s
                  goals.
                </div>
                <div>
                  We offer a collaborative approach to Network Consulting. We
                  have Network Engineers who has experience working within some
                  of the world’s most sophisticated networks, we work with you
                  to determine networking needs current and future.
                </div>
              </div>
            </div>
          </div>
          <div id="devops">
            <div className="d-flex container py-5 flex-column flex-md-row row-gap-4">
              <div className="col-md-6 section-icon-container d-flex justify-content-center align-items-center">
                <FontAwesomeIcon icon={faAws} size="8x" color="#4f86f9" />
              </div>
              <div className="col-md-6">
                <h2>DevOps</h2>
                <div>
                  Need to ensure fast, continuous delivery and a consistently
                  high level of software quality? An approach to software that
                  establishes close collaboration between software engineers and
                  IT operations employees, DevOps will help you achieve this by
                  automating and optimizing your IT processes. Our expert DevOps
                  engineers will help you attune the delivery, deployment,
                  development, security, and support of any high-load, fail-safe
                  system with microservices architecture — so your business
                  strategy can always rely on high-quality software.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
