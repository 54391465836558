import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodepen, faAws } from "@fortawesome/free-brands-svg-icons";
import {
  faLaptopCode,
  faMobileScreen,
  faGlobe,
  faFileCode,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
// import { services } from '../../constants';
export const HomeServices = () => {
  return (
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-lg-5 heading-section text-center mb-5"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            <span className="subheading">Services</span>
            <h2 className="mb-4">Our Exclusive Services We Offer For You</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div
            className="col-md-3 text-center d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="flow-wrap flow-wrap-right">
              <Link to="/services#itconsulting" style={{ width: "100%" }}>
                <div className="icon">
                  <FontAwesomeIcon icon={faFileCode} size="4x" />
                </div>
                <div className="text">
                  <h2>IT Consulting</h2>
                  {/* <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p> */}
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-md-3 text-center d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="flow-wrap">
              <Link to="/services#MAD" style={{ width: "100%" }}>
                <div className="icon">
                  <FontAwesomeIcon icon={faMobileScreen} size="4x" />
                </div>
                <div className="text">
                  <h2>Mobile Application Development</h2>
                  {/* <p className="mb-4">
                      We are working on both platforms (iOS and Android)
                    </p> */}
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-md-3 text-center d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="flow-wrap">
              <Link to="/services#SD" style={{ width: "100%" }}>
                <div className="icon">
                  <FontAwesomeIcon icon={faCodepen} size="4x" />
                </div>
                <div className="text">
                  <h2>Software Development</h2>
                  {/* <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p> */}
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-md-3 text-center d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="flow-wrap">
              <Link to="/services#webui" style={{ width: "100%" }}>
                <div className="icon">
                  <FontAwesomeIcon icon={faLaptopCode} size="4x" />
                </div>
                <div className="text">
                  <h2>Web/UI Development</h2>
                  {/* <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p> */}
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-md-3 text-center d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="flow-wrap">
              <Link to="/services#QA" style={{ width: "100%" }}>
                <div className="icon">
                  <FontAwesomeIcon icon={faHandshake} size="4x" />
                </div>
                <div className="text">
                  <h2>Quality Assurance</h2>
                  {/* <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p> */}
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-md-3 text-center d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="flow-wrap">
              <Link to="/services#network" style={{ width: "100%" }}>
                <div className="icon">
                  <FontAwesomeIcon icon={faGlobe} size="4x" />
                </div>
                <div className="text">
                  <h2>Network Management</h2>
                  {/* <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p> */}
                </div>
              </Link>
            </div>
          </div>
          <div
            className="col-md-3 text-center d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="flow-wrap">
              <Link to="/services#devops" style={{ width: "100%" }}>
                <div className="icon">
                  <FontAwesomeIcon icon={faAws} size="4x" />
                </div>
                <div className="text">
                  <h2>DevOps</h2>
                  {/* <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p> */}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
