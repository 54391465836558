import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { TopHeader } from "./TopHeader";
import { Header } from "./Header";
// import { FooterQuery } from './FooterQuery'
export const Layout = () => {
  return (
    <>
      <TopHeader />
      <Header />
      <div>
        <Outlet />
      </div>
      {/* <FooterQuery /> */}
      <Footer />
    </>
  );
};
