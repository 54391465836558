import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Header = () => {
  const pathname = useLocation().pathname;
  return (
    <nav className="navbar navbar-expand-lg  ftco-navbar-light">
      <div className="container-xl">
        <Link to="/" className="navbar-brand">
          {/* Union<small>corp.</small>
      <span>Finance &amp; Consultation</span> */}
          <img
            src="images/logo.png"
            width="180px"
            height="50px"
            alt="proleap systems"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="fa fa-bars"></span> Menu
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={`nav-link ${pathname === "/" && "active"}`}
                to="/"
              >
                Home
              </Link>
            </li>
            {/* <li className="nav-item"><Link className={`nav-link ${pathname === '/about' &&  'active'}`} to="about">About</Link></li> */}
            <li className="nav-item">
              <Link
                className={`nav-link ${pathname === "/services" && "active"}`}
                to="services"
              >
                Services
              </Link>
            </li>
            {/* <li className="nav-item"><Link className={`nav-link ${pathname === '/work' &&  'active'}`} to="work">Work</Link></li> */}
            {/* <li className="nav-item"><Link className={`nav-link ${pathname === '/pricing' &&  'active'}`} to="pricing">Pricing</Link></li> */}
            {/* <li className="nav-item"><Link className={`nav-link ${pathname === '/blog' &&  'active'}`} to="blog">Blog</Link></li> */}
            <li className="nav-item">
              <Link
                className={`nav-link ${pathname === "/contact" && "active"}`}
                to="contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
