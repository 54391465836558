import React from "react";
export const HomeAbout = () => {
  return (
    <section className="ftco-section">
      <div className="container-xl">
        <div className="row g-lg-5">
          <div
            className="col-lg-6 order-lg-last heading-section d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="mt-0 my-lg-5 py-5">
              {/* <span className="subheading">About ProLeap Systems</span> */}
              <h2 className="mb-4">
                {/* More than 100+ Trusted Clinets  */}
                About ProLeap Systems
              </h2>
              <p>
                Welcome to Proleap systems, we are a high-performance, IT
                staffing and consulting firm that producing best results for our
                clients, candidates, and employees.
              </p>
              <p>
                {" "}
                We provide best employees to our clients to achieve their
                business objectives and providing best opportunities to the
                employees to build their careers in great companies.
              </p>
              <p>
                We hire best talented, professionals who are updated with latest
                technologies. And we provide training to the employees to update
                their skills to build their careers
              </p>
              {/* <p className="mt-4">
                  <Link to="/" className="btn btn-primary py-3 px-4">
                    Learn More
                  </Link>
                </p> */}
            </div>
          </div>
          <div
            className="col-lg-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="500"
          >
            <div className="row">
              <div className="col-md-6 d-flex align-items-stretch services-wrap">
                <div className="services">
                  <div className="icon">
                    <span className="flaticon-accountant"></span>
                  </div>
                  <div className="text">
                    <h2>Professional Consultants</h2>
                    {/* <p className="mb-0">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia.
                      </p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch services-wrap">
                <div className="services">
                  <div className="icon">
                    <span className="flaticon-research"></span>
                  </div>
                  <div className="text">
                    <h2>Comprehensive Services</h2>
                    {/* <p className="mb-0">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia.
                      </p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch services-wrap">
                <div className="services">
                  <div className="icon">
                    <span className="flaticon-recession"></span>
                  </div>
                  <div className="text">
                    <h2>Robust and Legacy Code Delivery</h2>
                    {/* <p className="mb-0">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia.
                      </p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch services-wrap">
                <div className="services">
                  <div className="icon">
                    <span className="flaticon-money"></span>
                  </div>
                  <div className="text">
                    <h2>Industry Experience</h2>
                    {/* <p className="mb-0">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia.
                      </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
