import React from "react";
import { Link } from "react-router-dom";

export const HomeSlider = () => {
  return (
    <section className="slider-hero">
      <div className="overlay"></div>
      <div className="hero-slider">
        {[
          {
            heading: "We're Always Here To Help with tech!",
            content:
              "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
            featureImage: "images/xbg_1.jpg.pagespeed.ic.xaKMAgEA-P.jpg",
          },
          // {
          //   heading: "Build Your Financial Plan With Our Specialists",
          //   content:
          //     " Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
          //   featureImage: "images/xbg_2.jpg.pagespeed.ic.MXvbG99Jo5.jpg",
          // },
        ].map((slide, i) => {
          return (
            <div className="item" key={i}>
              <div className="work">
                <div
                  className="img img2 d-flex align-items-center js-fullheight flex "
                  style={{ backgroundImage: `url(${slide.featureImage})` }}
                >
                  <div className="container-xl">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-7">
                        <div className="text text-center mt-lg-5">
                          <h1 className="mb-4">{slide.heading}</h1>
                          {/* <p className="mb-4">{slide.content}</p> */}
                          <p>
                            <Link
                              to="/contact"
                              className="btn btn-white p-4 py-3"
                            >
                              Get Started
                              <span className="ion-ios-arrow-round-forward"></span>
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
