import React from "react";
import { HomeSlider } from "./Components/HomeSlider";
import { HomeAbout } from "./Components/HomeAbout";
import { HomeServices } from "./Components/HomeServices";
// import { HomeAboutUs } from "./Components/HomeAboutUs";
// import { HomeCaseStudy } from "./Components/HomeCaseStudy";
// import { HomeStats } from "./Components/HomeStats";
// import { HomeTeam } from "./Components/HomeTeam";
// import { HomeTestimonial } from "./Components/HomeTestimonial";
// import { HomeBlog } from "./Components/HomeBlog";
import { Helmet } from "react-helmet";

export const Home = () => {
  return (
    <div>
      <Helmet>
        <title>ProLeap Systems || Home</title>
      </Helmet>
      <HomeSlider />
      <HomeAbout />
      {/* <HomeAboutUs /> */}
      <HomeServices />
      {/* <HomeCaseStudy /> */}
      {/* <HomeStats /> */}
      {/* <HomeTeam /> */}
      {/* <HomeTestimonial /> */}
      {/* <HomeBlog /> */}
    </div>
  );
};
