import React from "react";
import { Link } from "react-router-dom";
import {
  address,
  email,
  mobile,
  services,
  servicesLinks,
  socialMedia,
} from "../constants";
export const Footer = () => {
  return (
    <footer
      className="ftco-footer img"
      style={{
        backgroundImage: "url(images/xbg_1.jpg.pagespeed.ic.xaKMAgEA-P.jpg)",
      }}
    >
      <div className="overlay"></div>
      <div className="container-xl">
        <div className="row mb-5 justify-content-between">
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2 logo d-flex">
                <Link to="/" className="navbar-brand">
                  ProLeap Systems
                  <span>Software Design And Development</span>
                </Link>
              </h2>
              <p>
                Welcome to Proleap systems, we are a high-performance, IT
                staffing and consulting firm that producing best results for our
                clients, candidates, and employees.
              </p>
              <ul className="ftco-footer-social mt-4">
                {/* <li className="mx-4">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={socialMedia.twitter}
                  >
                    <span className="fa fa-twitter"></span>
                  </a>
                </li>
                <li className="mx-4">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={socialMedia.facebook}
                  >
                    <span className="fa fa-facebook"></span>
                  </a>
                </li>
                <li className="mx-4">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={socialMedia.googlePlus}
                  >
                    <span className="fa fa-google"></span>
                  </a>
                </li> */}
                <li className="mx-4">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={socialMedia.instagram}
                  >
                    <span className="fa fa-instagram"></span>
                  </a>
                </li>
                <li className="mx-4">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={socialMedia.linkedIn}
                  >
                    <span className="fa fa-linkedin"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Services</h2>
              <div className="d-md-flex">
                <ul className="list-unstyled w-100">
                  {services.slice(0, 4).map((s, i) => {
                    return (
                      <li key={i}>
                        <Link to={`/services#${servicesLinks[i]}`}>
                          <span className="ion ion-ios-arrow-round-forward me-2"></span>
                          {s}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <ul className="list-unstyled w-100">
                  {services.slice(4, 8).map((s, i) => {
                    return (
                      <li key={i}>
                        <Link to={`/services#${servicesLinks[i + 4]}`}>
                          <span className="ion ion-ios-arrow-round-forward me-2"></span>
                          {s}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Recent Posts</h2>
              <div className="block-21 mb-4 d-flex">
                <Link to="#"
                  className="blog-img img rounded"
                  style={{
                    "backgroundImage":
                      "url(images/ximage_1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)",
                  }}
                ></Link>
                <div className="text">
                  <div className="meta">
                    <div>
                      <Link to="/">
                        <span className="fa fa-calendar"></span> Jan. 27, 2021
                      </Link>
                    </div>
                    <div>
                      <Link to="/">
                        <span className="fa fa-user"></span> Admin
                      </Link>
                    </div>
                  </div>
                  <h3 className="heading">
                    <Link to="/">
                      Incorporation is a big milestone for business
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="block-21 mb-4 d-flex">
                <Link to="#"
                  className="blog-img img rounded"
                  style={{
                    "backgroundImage":
                      "url(images/ximage_2.jpg.pagespeed.ic.mQGyn1P_Li.jpg)",
                  }}
                ></Link>
                <div className="text">
                  <div className="meta">
                    <div>
                      <Link to="/">
                        <span className="fa fa-calendar"></span> Jan. 27, 2021
                      </Link>
                    </div>
                    <div>
                      <Link to="/">
                        <span className="fa fa-user"></span> Admin
                      </Link>
                    </div>
                  </div>
                  <h3 className="heading">
                    <Link to="/">
                      Incorporation is a big milestone for business
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-md-6 col-lg">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Have a Questions?</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <span className="icon fa fa-map marker"></span>
                    <span className="text">
                      {address.line1}, {address.city}, {address.state}, USA,
                      {address.pincode}
                    </span>
                  </li>
                  <li>
                    <Link to="/">
                      <span className="icon fa fa-phone"></span>
                      <span className="text">{mobile}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span className="icon fa fa-paper-plane"></span>
                      <span className="text">{email}</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0 py-3 bg-darken text-sm">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12 text-center">
              <p className="mb-0" style={{ fontSize: "14px" }}>
                Copyright &copy; {new Date().getFullYear()}
                All rights reserved | Design and Developed By
                {/* <i className="fa fa-heart color-danger px-2" aria-hidden="true"></i> */}
                <a
                  href="https://structtechnologies.com"
                  rel="noreferrer"
                  target="_blank"
                  className="px-2"
                >
                  Struct Techologies pvt Ltd
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
